import { createSelector } from 'reselect';
import { EMPLOYEE } from '../constants/candidateTypes';
import { TEAM4TALENT, TALENT2TEST } from '../constants/companies';
import { FIELD_STAFF } from '../constants/userWorkTypes';

const selectAuthUserProfile = (state) =>
  state.auth != null && state.auth.user != null && state.auth.user.profile != null ? state.auth.user.profile : null;
const selectDBProfile = (state) => (state.profile != null ? state.profile.user : null);
const selectCompanySelectedUser = (state) => state.users.selectedUser && state.users.selectedUser.managingOtherUser ? state.users.selectedUser.tenantName : state.auth.user ? state.auth.user.profile.company : "";
const selectTenants = (state) => state.tenants != null ? state.tenants : null;

export const shouldBeAbleToSeeCreditsSelector = createSelector(
  selectAuthUserProfile,
  selectDBProfile,
  (authUserProfile, dbProfile) =>
    process.env.CREDITS_ENABLED &&
    authUserProfile != null &&
    dbProfile != null &&
    authUserProfile.candidate_type === EMPLOYEE &&
    authUserProfile.company === TEAM4TALENT &&
    dbProfile.userWorkType === FIELD_STAFF
);

export const shouldBeAbleToSeeUsertracksSelector = createSelector(
  selectAuthUserProfile,
  (authUserProfile) =>
    authUserProfile != null && authUserProfile.candidate_type === EMPLOYEE && (authUserProfile.company === TEAM4TALENT || authUserProfile.company === TALENT2TEST)
);

export const shouldBeAbleToSeeNews = createSelector(
  () =>
    process.env.NEWS_ENABLED
);

export const stopLoadingSelector = createSelector(
  (state) => state.system.isFetchingProfile,
  (state) => state.system.triedFetchingProfile,
  (state) => state.users.selectedUser.myportalUserId,
  (isFetchingProfile, triedFetchingProfile, myportalUserId) =>
    !isFetchingProfile && triedFetchingProfile && myportalUserId == null
);

export const shouldBeAbleToSeeTimesheets = createSelector(
  selectCompanySelectedUser,
  selectTenants,
  (company, tenantList) => {
    let validTenant = true;
    if (tenantList.tenants.length) {
      validTenant = [...tenantList.tenants].map(tenant => tenant.toLowerCase()).includes(company.toLowerCase());
    }
    return validTenant;
  }
);
